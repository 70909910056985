<template>
    <div class="screen-loader-wrap">
        <div class="screen-loader"></div>
    </div>
</template>

<script>
export default {
    name: 'ScreenLoader',
}
</script>

<style scoped>
@-webkit-keyframes pulse {
    50% {
        border-width: 30px;
    }
}

@keyframes pulse {
    50% {
        border-width: 30px;
    }
}

@-webkit-keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
.screen-loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}
.screen-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    height: 80px;
    margin: auto;
    border: 4px solid rgb(33, 37, 41);
    animation: spin 3s infinite linear;
}
.screen-loader::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border: 4px solid rgb(33, 37, 41);
    width: 60px;
    height: 60px;
    animation: pulse 1.5s infinite ease;
}
</style>
