<template>
    <template v-if="$route.meta && $route.meta.for_sp">
        <header class="sp-header"></header>
        <router-view/>
    </template>
    <template v-else>
    <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary mb-3">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{name: 'Schedule'}"><img class="logo" src="@/assets/logo.svg"> OYADORI</router-link>
            <template v-if="$store.state.auth && $store.state.auth.is_signed_in">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <div class="container-fluid">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'Schedule'}">
                                    <i class="bi bi-calendar3"></i> スケジュール
                                    </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'UndecidedSchedule'}">
                                    <i class="bi bi-egg-fill"></i>予定のたまご
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'SchoolList'}">
                                    <i class="bi bi-mortarboard-fill"></i> 学校・イベント
                                </router-link>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="bi bi-gear-fill"></i> 管理
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="productDropdown">
                                    <li>
                                        <router-link class="dropdown-item" :to="{name: 'EmployeeList'}">
                                            <i class="bi bi-person-fill me-2"></i>社員
                                        </router-link>
                                        <router-link class="dropdown-item" :to="{name: 'PartnerList'}">
                                            <i class="bi bi-people-fill me-2"></i>応援カメラマン
                                        </router-link>
                                        <router-link class="dropdown-item" :to="{name: 'Skill'}">
                                            <i class="bi bi-stars me-2"></i>スキル
                                        </router-link>
                                        <router-link class="dropdown-item" :to="{name: 'Car'}">
                                            <i class="bi bi-truck me-2"></i>社用車
                                        </router-link>
                                        <router-link class="dropdown-item" :to="{name: 'Equipment'}">
                                            <i class="bi bi-card-checklist me-2"></i>備品
                                        </router-link>
                                        <router-link class="dropdown-item" :to="{name: 'PartnerPassword'}">
                                            <i class="bi bi-shield-lock me-2"></i>応援カメラマン用パスワード
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-person-circle"></i> {{ $store.state.auth.photographer_name }} さん
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="productDropdown">
                                <li>
                                    <a class="dropdown-item" href="#" @click="signOut()">
                                        <i class="bi bi-door-open"></i>ログアウト
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </nav>

    <div class="container container-main">
        <router-view/>
    </div>
    </template>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/components/tools/ScreenLoader.vue'
import MessageDialog from '@/components/tools/MessageDialog.vue'

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
        }
    },
    data() {
        return {
            loading: 0,
            dialog: {
                title: null,
                message: null,
                icon: null
            },
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });
    },
    methods: {
        signOut() {
            this.$store.state.condition.conditions.splice(0);
            this.$store.commit('auth/signOut')
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        }
    },
    computed: {
        user_name() {
            return this.$store.state.auth.user_name;
        },
    }
}
</script>

<style>
.container {
    background: #FFF;
    padding-top: 1em;
    padding-bottom: 1em;
}
.logo {
    width: auto;
    height: 20px;
    vertical-align: middle;
}
</style>
