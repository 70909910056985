// 判定系

// 数値3桁区切り
export function numberFormat(value) {
    if (value === null || typeof value === 'undefined') {
        return null;
    }

    // 整数でない場合（小数点）
    if (!Number.isInteger(value)) {
        // 数値変換
        return Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 });
    }

    // 数値変換
    return Number(value).toLocaleString();
}
