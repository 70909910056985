// 判定系

// null または undefined
export function isNud(value) {
    if (value === null || value === '' || typeof value === 'undefined') {
        return true;
    }

    return false;
}
