import Enum from '../enum'

/**
 * 社員 権限
 */
class Role extends Enum {
    static MANAGE_SCHOOL = 10; // 学校・イベント管理
    static MANAGE_EMPLOYEE = 20; // 社員管理
    static MANAGE_PARTNER = 30; // 応援カメラマン管理
    static MANAGE_PARTNER_PASSWORD = 40; // 応援カメラマンパスワード管理

    static values() {
        return {
            [this.MANAGE_SCHOOL]: '学校・イベント管理',
            [this.MANAGE_EMPLOYEE]: '社員管理',
            [this.MANAGE_PARTNER]: '応援カメラマン管理',
            [this.MANAGE_PARTNER_PASSWORD]: '応援カメラマンパスワード管理',
        }
    }
}

export default Role;