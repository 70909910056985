<template>
    <transition name="fade">
        <div v-if="shown">
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" @click="hide"></button>
                        </div>
                        <div class="modal-body m-auto">
                            <div :class="{'success-circle': (icon === 'success'), 'error-circle': (icon === 'error'), 'm-auto mt-3': true  }">
                                <span class="check" v-if="icon === 'success'">&check;</span>
                                <span class="check" v-if="icon === 'error'">&cross;</span>
                            </div>
                            <div class="mt-md-4 text-center">
                                <h5 class="modal-title mb-md-3">{{ title }}</h5>
                                <p class="multiline">{{ message }}</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="hide">閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'MessageDialog',
    data() {
        return {
            shown: false
        }
    },
    props: {
        title: {
            type: String,
            default: '完了'
        },
        message: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'success'
        }
    },
    methods: {
        show() {
            this.shown = true;
        },
        hide() {
            this.shown = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.success-circle {
    position: relative;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: inset 0 0 0 2px #fff;
    animation: success-filling 0.3s ease-in 0.1s forwards;
}
.error-circle {
    position: relative;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: inset 0 0 0 2px #fff;
    animation: error-filling 0.3s ease-in 0.1s forwards;
}
.check {
    position: absolute;
    color: #fff;
    width: 80px;
    font-size: 1.5rem;
    line-height: 80px;
    text-align: center;
    top: 0;
    opacity: 0;
    animation: check 0.3s ease-in-out 0.1s forwards;
}

/* 円を描くアニメーション */
@keyframes draw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
/* 塗りのアニメーション */
@keyframes success-filling {
    0% {
        box-shadow: inset 0 0 0 9px #56b870;
    }
    100% {
        box-shadow: inset 0 0 0 50px #56b870;
    }
}
@keyframes error-filling {
    0% {
        box-shadow: inset 0 0 0 9px #dd3544;
    }
    100% {
        box-shadow: inset 0 0 0 50px #dd3544;
    }
}
/* チェックマークのアニメーション */
@keyframes check {
    0% {
        transform: scale(0.5) rotate(30deg);
        opacity: 0;
    }
    90% {
        transform: scale(.8) rotate(-15deg);
        opacity: 1;
    }
    100% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
}
</style>
