class Enum {

    static values() {
        return {}
    }

    static get(key) {
        const values = this.values();

        if (key in values) {
            return values[key];
        }

        return null;
    }

    static join(array) {
        if (array === null || array === "") {
            return null;
        }
        const values = this.values();
        const labels = [];

        for (let key of array) {
            labels.push(values[key]);
        }

        return labels.join(', ');
    }

    static options() {
        let object = this.values();
        let array = [];
        let keys = Object.keys(object);
        keys.forEach((key) => {
            array.push({
                key: key,
                label: object[key],
            });
        });

        return array;
    }
}

export default Enum;
